<template>
  <DomainSettings mode="edit" :route-on-action="getRouteOnSave()" />
</template>

<script>
import DomainSettings from '@/components/domain/DomainSettings'

export default {
  components: {
    DomainSettings: DomainSettings
  },
  methods: {
    getRouteOnSave() {
      return { path: this.$store.getters['domain/fullPath'] }
    }
  }
}
</script>
