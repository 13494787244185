<template>
  <v-text-field
    type="number"
    :label="$props.label"
    v-model="price"
    v-bind="{ ...$props, ...$attrs }"
    @input="$emit('input', price)"
    :rules="[value => rules.onlyNumbers(value) || $t('settings.price_error')]"
  />
</template>

<script>
import { rules } from '../../validation/rules'

export default {
  name: 'Price',
  props: ['value', 'label'],
  data() {
    return {
      price: this.$f.integer(this.$props.value),
      rules: { ...rules }
    }
  }
}
</script>
