<template>
  <div id="sale-text-index">
    <Headline :text="$t('settings.manage_sale_texts_label')" />
    <SaleTextIndex @dataChanged="$emit('dataChanged')" />
  </div>
</template>

<script>
import Headline from '@/components/app/Headline'
import SaleTextIndex from '@/components/sale-text/SaleTextIndex'

export default {
  name: 'Index',
  components: {
    Headline,
    SaleTextIndex
  }
}
</script>
