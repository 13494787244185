<template>
  <div>
    <Grid data-store-action="sale_text/fetchData" :headers="headers" ref="grid" :enable-pagination="false" class="py-2">
      <template v-slot:[`item.text`]="{ item }">
        <span class="text"><Crop max-width="450px" :value="$f.implode(item.text, ' ')"/></span>
      </template>
      <template v-slot:[`item.type`]="{ item }">
        <span class="type">{{ item.type }}</span>
      </template>
    </Grid>
    <PrimaryButton @click="showDialog = true" class="mt-4">{{ $t('settings.add_sale_text') }}</PrimaryButton>
    <v-dialog v-model="showDialog" width="500">
      <SaleTextAdd @sale_text:saved="onSave" @sale_text:canceled="showDialog = false" />
    </v-dialog>
  </div>
</template>

<script>
import GridBaseMixin from '@/components/grid/GridBaseMixin'
import Grid from '@/components/grid/Grid'
import SaleTextAdd from '@/components/sale-text/SaleTextAdd.vue'
import Crop from '@/components/others/Crop.vue'
import PrimaryButton from '@/components/app/PrimaryButton.vue'

export default {
  mixins: [GridBaseMixin],
  components: { PrimaryButton, Crop, Grid, SaleTextAdd },
  data() {
    return {
      headers: [
        { text: 'Text', value: 'text', sortable: false },
        { text: 'Type', value: 'type', sortable: false }
      ],
      showDialog: false
    }
  },
  methods: {
    onSave() {
      this.showDialog = false
      this.$refs.grid.getDataFromApi()
      this.$emit('dataChanged')
    }
  }
}
</script>

<style lang="sass">
@import '~@/sass/variables.sass'

#app #sale-text-index .grid .column-text
  width: 750px
</style>
