var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-switch',{class:{
    'mt-0': true,
    'mb-8': true,
    'form-switch': true,
    'active-form-switch': _vm.isActive,
    'inactive-form-switch': !_vm.isActive,
    'disabled-form-switch': _vm.$props.isDisabled
  },attrs:{"input-value":_vm.value,"true-value":_vm.$props.trueValue,"false-value":_vm.$props.falseValue,"label":_vm.$props.label,"hide-details":"","inset":"","disabled":_vm.$props.isDisabled},on:{"change":_vm.onChange,"click":function($event){return _vm.$emit('click')}}})}
var staticRenderFns = []

export { render, staticRenderFns }