<template>
  <v-form class="settings-form" v-if="currentSettings != null" v-model="isAllValid" ref="form">
    <v-card>
      <v-tabs v-model="tab" class="tab-no-slider mb-6 setting-tabs" grow icons-and-text centered>
        <v-tab :key="tabItems[0]" id="v-tab-sale-settings">
          <span class="text">{{ $t('settings.sale') }}</span>
          <v-icon size="x-large">mdi-currency-usd</v-icon>
        </v-tab>
        <v-tab :key="tabItems[1]" id="v-tab-general-settings">
          <span class="text">{{ $t('settings.general') }}</span>
          <v-icon size="x-large">mdi-playlist-edit</v-icon>
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab" outlined class="mx-3 px-3">
        <v-tab-item :key="tabItems[0]">
          <SettingsSale :settings="currentSettings" :type="type" :show-sale-settings="showSettings" />
        </v-tab-item>
        <v-tab-item :key="tabItems[1]">
          <SettingsGeneral :settings="currentSettings" :type="type" :show-parking-settings="showSettings" />
        </v-tab-item>
      </v-tabs-items>
      <v-row no-gutters>
        <v-col class="col-12 pe-6 py-4 text-right">
          <PlainButton
            v-if="$props.cancelable"
            class="cancel-settings-btn"
            @click="$emit('cancel')"
            :waiting-for-end-of-action="$props.waitingForEndOfAction"
          >
            {{ $t('misc.cancel') }}
          </PlainButton>
          <PrimaryButton class="save-settings-btn ml-2" @click="saveIfValid" :waiting-for-end-of-action="$props.waitingForEndOfAction">
            {{ $t('misc.save') }}
          </PrimaryButton>
        </v-col>
      </v-row>
    </v-card>
  </v-form>
</template>

<script>
import SettingsGeneral from '@/components/settings/SettingsGeneral'
import SettingsSale from '@/components/settings/SettingsSale'
import PrimaryButton from '@/components/app/PrimaryButton'
import PlainButton from '@/components/app/PlainButton'

export default {
  components: {
    PlainButton,
    PrimaryButton,
    SettingsGeneral,
    SettingsSale
  },
  props: {
    settings: Object,
    showSettings: {
      Boolean,
      default: true
    },
    activeTab: {
      Number,
      default: 0
    },
    type: {
      String,
      default: 'domain'
    },
    cancelable: {
      Boolean,
      default: false
    },
    waitingForEndOfAction: {
      Boolean,
      default: false
    }
  },
  data() {
    return {
      tab: this.activeTab,
      tabItems: ['sale_settings', 'general_settings'],
      currentSettings: this.settings,
      isAllValid: true
    }
  },
  methods: {
    saveIfValid() {
      if (!this.isAllValid) {
        this.$store.dispatch('snackbar/showErrorMessage', { message: this.$t('misc.form_error') })
        return
      }
      this.$emit('save', this.currentSettings)
    }
  },
  watch: {
    settings: {
      handler(settings) {
        this.currentSettings = settings
      },
      deep: true
    },
    activeTab: {
      handler(activeTab) {
        this.tab = activeTab
      }
    }
  }
}
</script>
