<template>
  <div>
    <Grid data-store-action="sale_url/fetchData" :headers="headers" ref="grid" :enable-pagination="false" class="py-2">
      <template v-slot:[`item.name`]="{ item }">
        <span class="name"><Crop max-width="150px" :value="item.name"/></span>
      </template>
      <template v-slot:[`item.url`]="{ item }">
        <span class="url"><Crop max-width="300px" :value="item.url"/></span>
      </template>
    </Grid>
    <PrimaryButton @click="showDialog = true" class="mt-4">{{ $t('settings.add_sale_url') }}</PrimaryButton>
    <v-dialog v-model="showDialog" width="500">
      <SaleUrlAdd @sale_url:saved="onSave" @sale_url:canceled="showDialog = false" />
    </v-dialog>
  </div>
</template>

<script>
import GridBaseMixin from '@/components/grid/GridBaseMixin'
import Grid from '@/components/grid/Grid'
import SaleUrlAdd from '@/components/sale-url/SaleUrlAdd.vue'
import Crop from '@/components/others/Crop.vue'
import PrimaryButton from '@/components/app/PrimaryButton.vue'

export default {
  mixins: [GridBaseMixin],
  components: { PrimaryButton, Crop, SaleUrlAdd, Grid },
  data() {
    return {
      headers: [
        { text: 'Name', value: 'name', sortable: false },
        { text: 'Url', value: 'url', sortable: false }
      ],
      showDialog: false
    }
  },
  methods: {
    onSave() {
      this.showDialog = false
      this.$refs.grid.getDataFromApi()
      this.$emit('dataChanged')
    }
  }
}
</script>

<style lang="sass">
@import '~@/sass/variables.sass'

#app #sale-url-index .grid .column-name
  width: 450px
</style>
