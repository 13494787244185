<template>
  <div>
    <ManageLink :label="$t('settings.manage_sale_texts_label')" @openDialog="showDialog = true" />
    <v-dialog v-model="showDialog" hide-overlay transition="dialog-bottom-transition" content-class="sale-texts-dialog">
      <v-container class="pa-4 dialog-background">
        <SaleTextIndex @dataChanged="onChangedData" />
        <v-btn icon class="close-button-sticky" @click="showDialog = false"><v-icon>mdi-close</v-icon></v-btn>
      </v-container>
    </v-dialog>
  </div>
</template>

<script>
import SaleTextIndex from '@/pages/sale-text/Index.vue'
import ManageLink from '@/components/settings/ManageLink.vue'

export default {
  name: 'SaleTextsDialog',
  components: { SaleTextIndex, ManageLink },
  data() {
    return {
      showDialog: false
    }
  },
  methods: {
    onChangedData() {
      this.showDialog = false
      this.$emit('dataChanged')
    }
  }
}
</script>

<style lang="sass">
@import '~@/sass/variables.sass'

#app
  .sale-texts-dialog
    height: 80%
    @media only screen and (min-width: 1264px)
      margin-left: 290px
  .dialog-background
    position: relative
  .close-button-sticky
    position: absolute
    top: 10px
    right: 10px
</style>
