<template>
  <div id="sale-url-index">
    <Headline :text="$t('settings.manage_sale_urls_label')" />
    <SaleUrlIndex @dataChanged="$emit('dataChanged')" />
  </div>
</template>

<script>
import Headline from '@/components/app/Headline'
import SaleUrlIndex from '@/components/sale-url/SaleUrlIndex.vue'

export default {
  name: 'Index',
  components: {
    Headline,
    SaleUrlIndex
  }
}
</script>
