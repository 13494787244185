<template>
  <div class="general-settings">
    <v-row v-if="this.type === 'folder'">
      <v-col>
        <div class="name-field">
          <v-text-field
            id="folder-name"
            :label="$t('settings.name')"
            :rules="[value => rules.required(value) || $t('folder_settings.name_required')]"
            v-model="currentSettings.name"
            dense
            filled
          ></v-text-field>
        </div>
      </v-col>
    </v-row>
    <v-row v-if="this.type === 'domain'" class="mb-4">
      <v-col>
        <div class="domain-notes">
          <v-textarea
            filled
            v-model="currentSettings.note_to_domain"
            :placeholder="$t('settings.domain_notes_notice')"
            id="note-to-domain-textarea"
            hide-details
          ></v-textarea>
        </div>
      </v-col>
    </v-row>

    <v-row v-if="this.type === 'folder'">
      <v-col>
        <div class="notes">
          <v-textarea filled v-model="currentSettings.note" :placeholder="$t('settings.note')" id="folder-note"></v-textarea>
        </div>
      </v-col>
    </v-row>
    <v-row class="mt-4">
      <v-col>
        <div class="use-settings-switch" v-if="this.type === 'folder'">
          <FormSwitch
            :label="$t('settings.use_settings')"
            v-model="currentSettings.use_settings"
            :true-value="true"
            :false-value="false"
            @click="this.checkUseSettings"
          />
          <HelpInfo :description="$t('settings.use_settings_description')" />
        </div>
        <div class="directadv-switch" v-if="(showParkingSettings && this.type === 'domain') || this.type === 'account'">
          <FormSwitch :label="$t('settings.direct_advertiser')" v-model="currentSettings.directadv" :true-value="1" :false-value="0" />
        </div>
        <div class="show_domainname-switch" v-if="showParkingSettings">
          <FormSwitch :label="$t('settings.show_domain_name')" v-model="currentSettings.show_domainname" true-value="yes" false-value="no" />
        </div>
        <div class="useimprint-switch" v-if="showParkingSettings">
          <FormSwitch :label="$t('settings.imprint')" v-model="currentSettings.useimprint" :true-value="1" :false-value="0" />
        </div>
      </v-col>
    </v-row>

    <v-row v-if="showParkingSettings && this.type === 'account'">
      <v-col cols="6">
        <div class="imprint-type-select">
          <v-select
            :label="$t('settings.imprint_type')"
            :items="['link', 'text']"
            v-model="currentSettings.imprint_type"
            filled
            dense
            hide-details
            :menu-props="{ contentClass: 'imprint-type-select', bottom: true, offsetY: true }"
          />
        </div>
      </v-col>
    </v-row>

    <v-row v-if="showParkingSettings && this.type === 'account' && currentSettings.imprint_type === 'link'" class="mb-4">
      <v-col cols="6">
        <div class="g-imprint-link-input">
          <v-text-field :label="$t('settings.g_imprint_link')" v-model="currentSettings.g_imprint_link" dense filled hide-details />
        </div>
      </v-col>
    </v-row>

    <v-row v-if="showParkingSettings && this.type === 'account' && currentSettings.imprint_type === 'text'" class="mb-4">
      <v-col cols="6">
        <div class="g-imprint-textarea">
          <v-textarea :label="$t('settings.g_imprint')" dense filled hide-details v-model="currentSettings.g_imprint" />
        </div>
      </v-col>
    </v-row>

    <v-dialog v-model="showApplySettingsNoticeDialog" hide-overlay content-class="apply-settings-notice-dialog">
      <v-card class="pa-4">
        <v-row>
          <v-col cols="12">
            {{ $t('settings.use_settings_description') }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="8">
            <v-checkbox label="Don't show this again" hide-details v-model="dontShowApplySettingsNotice" class="dont-show-again"></v-checkbox>
          </v-col>
          <v-col cols="4" class="text-right">
            <PlainButton class="close-button mt-3" @click="showApplySettingsNoticeDialog = false">Close</PlainButton>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { rules } from '@/validation/rules'
import HelpInfo from '@/components/others/HelpInfo.vue'
import DateHelper from '@/util/DateHelper'
import PlainButton from '@/components/app/PlainButton.vue'
import FormSwitch from '@/components/app/FormSwitch'
import LocalStore from '@/util/LocalStore'

export default {
  name: 'SettingsGeneral',
  components: { PlainButton, HelpInfo, FormSwitch },
  props: {
    settings: Object,
    type: {
      String,
      default: 'domain'
    },
    showParkingSettings: {
      Boolean,
      default: true
    }
  },
  data() {
    return {
      rules: { ...rules },
      currentSettings: this.$props.settings,
      showApplySettingsNoticeDialog: false,
      dontShowApplySettingsNotice: false
    }
  },
  methods: {
    checkUseSettings: function() {
      if (this.currentSettings.use_settings) {
        let dont_show_apply_settings_notice = LocalStore.getDontShowApplySettingsNotice()
        let oldest_date = DateHelper.daysAgoAsString(30)
        if (!dont_show_apply_settings_notice || dont_show_apply_settings_notice < oldest_date) {
          this.showApplySettingsNoticeDialog = true
        }
      }
    }
  },
  watch: {
    dontShowApplySettingsNotice: function(val) {
      if (val) {
        LocalStore.setDontShowApplySettingsNotice(DateHelper.todayAsString())
      } else {
        LocalStore.removeDontShowApplySettingsNotice()
      }
    }
  }
}
</script>

<style lang="sass">
@import '~@/sass/variables.sass'
#app
  .use-settings-switch .v-input
    display: inline-block
  .apply-settings-notice-dialog
    max-width: 520px
    a.close-button:hover
      text-decoration: underline
</style>
