<template>
  <v-btn @click="$emit('openDialog')" class="manage-link pa-0">
    <v-icon size="x-large" class="mr-2 mt-1">mdi-playlist-edit</v-icon>
    <span class="text">{{ label }}</span>
  </v-btn>
</template>

<script>
export default {
  name: 'ManageLink',
  props: {
    label: {
      String,
      required: true
    }
  }
}
</script>

<style lang="sass">
@import '~@/sass/variables.sass'

#app .manage-link
  background: none
  border: none
  box-shadow: none
  text-transform: none
  color: $primary !important
  .text:hover
    text-decoration: underline
  &:before
    content: none
</style>
