<template>
  <div>
    <SettingsEdit
      :settings="currentDomainSettings"
      @save="domainChanged"
      @cancel="backToRoute"
      v-if="currentDomainSettings != null"
      ref="settings"
      :cancelable="true"
      :waiting-for-end-of-action="actionRunning"
    />
    <Dialog :visible="showConsentDialog" dialogMaxWidth="500" @dialog:close="backToRoute" @dialog:confirmed="showConsentDialog = false">
      <template v-slot:title>
        {{ $tc('domain_edit_multiple.heading_dialog', getSelectedDomainsCount()) }}
      </template>
      <template v-slot:content>
        {{ $t('domain_edit_multiple.domain_settings_overwrite') }}
      </template>
      <template v-slot:buttonText>
        {{ $t('misc.continue') }}
      </template>
    </Dialog>
  </div>
</template>

<script>
import SettingsEdit from '@/components/settings/SettingsEdit'
import Dialog from '@/components/others/Dialog'
import RouteQueryHelper from '@/util/RouteQueryHelper'

export default {
  components: {
    Dialog,
    SettingsEdit
  },
  props: {
    mode: {
      String,
      default: 'edit'
    },
    routeOnAction: {
      Object,
      default: () => null
    }
  },
  created() {
    if (this.$props.mode === 'edit') {
      this.$store.dispatch('domain/fetchDomain', this.$route.params.domain).then(domain => {
        this.batchUpdateDomains = [domain]
        this.currentDomainSettings = { ...domain }
      })
    }
    if (this.$props.mode === 'edit-multiple') {
      this.showConsentDialog = true
      this.currentDomainSettings = {}
      this.batchUpdateDomains = this.$store.getters['domain/getBatchUpdateDomain']
    }
  },
  data() {
    return {
      currentDomainSettings: null,
      showConsentDialog: false,
      batchUpdateDomains: [],
      actionRunning: false
    }
  },
  methods: {
    domainChanged(updatedSettings) {
      this.batchUpdateDomains.length > 0 && this.batchUpdateDomains.length <= 500
        ? this.batchUpdate(updatedSettings)
        : this.bulkUpdate(updatedSettings)
    },
    batchUpdate(updatedSettings) {
      const updateValues = this.getUpdateValues(updatedSettings)
      const batches = this.batchUpdateDomains.map(batchUpdateDomain => {
        return {
          route: '/domains/' + batchUpdateDomain.domain,
          method: 'PUT',
          id: batchUpdateDomain.domain,
          parameters: updateValues
        }
      })
      this.actionRunning = true
      this.$store
        .dispatch('batch/store', { requests: batches })
        .then(async response => {
          if (response.errorIds.length === 0) {
            await this.$store.dispatch('snackbar/showSuccessMessage', { message: this.$tc('domain_edit.domain_updated_success', batches.length) })
            this.backToRoute()
          } else if (response.errorIds.length === batches.length) {
            await this.$store.dispatch('snackbar/showErrorMessage', {
              message: this.$tc('domain_edit.domain_updated_error', batches.length),
              details: response.errorMessages
            })
          } else {
            let message =
              this.$tc('domain_edit.domain_updated_warning_count_success', batches.length - response.errorIds.length) +
              ' ' +
              this.$tc('domain_edit.domain_updated_warning_count_failed', response.errorIds.length)
            await this.$store.dispatch('snackbar/showWarningMessage', {
              message: message,
              subMessage: response.errorMessages.join(', ').trim(),
              details: response.errorIds
            })
          }
          this.actionRunning = false
        })
        .catch(() => {
          this.$parent.$emit('api_error')
          this.actionRunning = false
        })
    },
    bulkUpdate(updatedSettings) {
      const data = {
        action: 'edit',
        filters: this.getFiltersFromQueryForBulkUpdate(),
        arguments: this.getArgumentsFromQueryForBulkUpdate(),
        update_values: this.getUpdateValues(updatedSettings)
      }
      this.actionRunning = true
      this.$store
        .dispatch('bulk_request/store', data)
        .then(async () => {
          await this.$store.dispatch('snackbar/showInfoMessage', {
            message: this.$t('bulk.bulk_queued'),
            subMessage: this.$t('bulk.bulk_domains_updated')
          })
          this.backToRoute()
          this.actionRunning = false
        })
        .catch(() => {
          this.$parent.$emit('api_error')
          this.actionRunning = false
        })
    },
    getSelectedDomainsCount() {
      let selectedDomainsCount = this.$store.getters['domain/selectedDomainsCount']
      return selectedDomainsCount !== null ? selectedDomainsCount : 1
    },
    getFiltersFromQueryForBulkUpdate() {
      return RouteQueryHelper.fromQuery(this.$route.query)
        .remove('sort')
        .getAll()
    },
    getArgumentsFromQueryForBulkUpdate() {
      return RouteQueryHelper.fromQuery(this.$route.query)
        .removeExceptMany(['sort'])
        .getAll()
    },
    getUpdateValues(updatedSettings) {
      let updateValues = { ...this.getSaleSettingFields(updatedSettings), ...this.getGeneralSettingsFields(updatedSettings) }
      this.removeObjectKeysWithUndefinedValues(updateValues)
      return updateValues
    },
    removeObjectKeysWithUndefinedValues(updateValues) {
      Object.keys(updateValues).forEach(key => updateValues[key] === undefined && delete updateValues[key])
    },
    getGeneralSettingsFields(updatedDomain) {
      return {
        directadv: updatedDomain.directadv,
        useimprint: updatedDomain.useimprint,
        show_domainname: updatedDomain.show_domainname,
        note_to_domain: updatedDomain.note_to_domain ?? ''
      }
    },
    getSaleSettingFields(updatedDomain) {
      let saleSettings = {}
      saleSettings.forsale_type = updatedDomain.forsale_type

      switch (updatedDomain.forsale_type) {
        case 'contact':
          saleSettings.forsale_template = updatedDomain.forsale_template
          saleSettings.forsale_linkstyle = updatedDomain.forsale_linkstyle
          saleSettings.custom_text = updatedDomain.custom_text
          saleSettings.min_offer = updatedDomain.min_offer
          saleSettings.currency = updatedDomain.currency
          saleSettings.captcha = updatedDomain.captcha
          break
        case 'externallink':
          saleSettings.sellurl = updatedDomain.sellurl
          saleSettings.forsale_linkstyle = updatedDomain.forsale_linkstyle
          saleSettings.custom_text = updatedDomain.custom_text
          break
      }

      return saleSettings
    },
    backToRoute() {
      this.$props.routeOnAction.path !== null ? this.$router.push(this.$props.routeOnAction) : this.$router.push({ path: '/domains?is_deleted=0' })
    }
  }
}
</script>
