<template>
  <p>
    <strong>
      {{ text }}
      <slot></slot>
    </strong>
  </p>
</template>

<script>
export default {
  name: 'Subtitle',
  props: {
    text: {
      required: true
    }
  }
}
</script>
