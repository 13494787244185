<template>
  <div class="add-sale-url-form">
    <v-card class="pa-4">
      <Headline :text="$t('settings.add_sale_url')" />
      <v-form>
        <v-text-field
          :label="$t('settings.new_for_sale_own_link')"
          v-model="newSaleUrl"
          :rules="[
            value => rules.required(value) || $t('settings.new_for_sale_own_link') + $t('misc.is_required'),
            value => rules.url(value) || $t('settings.new_for_sale_own_link') + $t('misc.is_not_valid')
          ]"
          ref="newSaleUrl"
          id="new-sale-url"
          persistent-hint
          :hint="$t('settings.new_for_sale_own_link_hint')"
          filled
          dense
        />
        <v-text-field
          :label="$t('settings.new_for_sale_own_link_name_label')"
          v-model="newSaleUrlName"
          :rules="[value => rules.required(value) || $t('settings.new_for_sale_own_link_name_error')]"
          ref="newSaleUrlName"
          id="new-sale-url-name"
          persistent-hint
          :hint="$t('settings.new_for_sale_own_link_name_hint')"
          filled
          dense
        />
        <v-card-actions>
          <v-spacer></v-spacer>
          <PlainButton @click="$emit('sale_url:canceled')" class="dialog-close-btn">Cancel</PlainButton>
          <PrimaryButton @click="saveNewSaleUrl" class="save-new-sale-url">{{ $t('misc.add') }}</PrimaryButton>
        </v-card-actions>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import { rules } from '@/validation/rules'
import Headline from '@/components/app/Headline.vue'
import PrimaryButton from '@/components/app/PrimaryButton.vue'
import PlainButton from '@/components/app/PlainButton.vue'
import Logger from '@/util/Logger'

export default {
  name: 'SaleUrlAdd',
  components: { Headline, PlainButton, PrimaryButton },
  data() {
    return {
      newSaleUrl: 'https://',
      newSaleUrlName: '',
      rules: { ...rules }
    }
  },
  methods: {
    saleUrlFieldsValid() {
      return (this.rules.required(this.newSaleUrlName) && this.rules.required(this.newSaleUrl) && this.rules.url(this.newSaleUrl)) ?? true
    },
    saveNewSaleUrl() {
      if (this.saleUrlFieldsValid()) {
        let storeData = {
          name: this.newSaleUrlName,
          url: this.newSaleUrl
        }

        this.$store
          .dispatch('sale_url/saveData', storeData)
          .then(async () => {
            this.$emit('sale_url:saved')

            await this.$store.dispatch('snackbar/showSuccessMessage', { message: this.$t('settings.new_for_sale_own_link_added') })
            this.resetSaleUrlFields()
          })
          .catch(error => {
            Logger.log('Error saving new sale url', { error })
          })
      } else {
        this.$store.dispatch('snackbar/showErrorMessage', { message: this.$t('settings.new_for_sale_own_link_global_error') })
      }
    },
    resetSaleUrlFields() {
      this.showNewSaleUrlFields = false
      this.newSaleUrl = 'https://'
      this.newSaleUrlName = ''
      this.$refs.newSaleUrl.resetValidation()
      this.$refs.newSaleUrlName.resetValidation()
    }
  }
}
</script>
