<template>
  <v-row>
    <v-col>
      <v-row class="sale-settings" v-if="showSaleSettings">
        <v-col cols="12" lg="6" order="1">
          <div class="forsale-field mb-8">
            <Subtitle :text="$t('settings.forsale_type')" />
            <v-btn-toggle v-model="currentSettings.forsale_type" mandatory class="d-flex flex-row align-stretch forsale-type-switch">
              <v-btn value="notforsale" @click="showOrHideSaleFields('notforsale')" class="flex-grow-1">
                {{ $t('settings.forsale_type_notforsale') }}
              </v-btn>
              <v-btn value="contact" @click="showOrHideSaleFields('contact')" class="flex-grow-1">
                {{ $t('settings.forsale_type_contact') }}
              </v-btn>
              <v-btn value="externallink" @click="showOrHideSaleFields('externallink')" class="flex-grow-1"
                >{{ $t('settings.forsale_type_externallink') }}
              </v-btn>
            </v-btn-toggle>
          </div>

          <div class="sale-fields">
            <div v-if="showSaleFieldExternalLink" class="forsale-url-switch mb-8">
              <Subtitle :text="$t('settings.sale_url')" />
              <v-select
                :items="availableSaleUrls"
                :label="$t('domain_index.for_sale_url')"
                v-model="currentSettings.sellurl"
                item-value="id"
                filled
                dense
                :menu-props="{ contentClass: 'sale-url-select', bottom: true, offsetY: true }"
                :hide-details="true"
              >
                <template v-slot:selection="data">
                  <Crop max-width="530px" :value="data.item.name" />
                </template>
                <template v-slot:item="data">
                  <Crop max-width="530px" :value="data.item.name" />
                </template>
              </v-select>

              <SaleUrlsDialog @dataChanged="loadSaleUrls" />
            </div>

            <div v-if="showSaleFieldContact" class="forsale-template-switch mb-4">
              <Subtitle :text="$t('settings.form_type')">
                <HelpInfo :description="$t('settings.form_type_description')" />
              </Subtitle>
              <v-radio-group v-model="currentSettings.forsale_template" class="pl-2 mt-0 pt-0 dark-label" row>
                <v-radio :label="template.name" :value="template.id" v-for="template in availableContactSaleTemplates" :key="template.id"></v-radio>
              </v-radio-group>
              <v-row class="forsale-minoffer ma-0 mt-0 pt-0" v-if="currentTemplate != null && currentTemplate.offer == 'yes'">
                <v-col class="pa-0 mt-0 pt-0" col="12" md="4">
                  <Price
                    :label="$t('domain_index.minimum_offer')"
                    :value="currentSettings.min_offer"
                    filled
                    dense
                    @input="currentSettings.min_offer = parseInt($event)"
                  />
                </v-col>
                <v-col class="forsale-minoffer-currency col-5 pa-0 pl-2 dark-label">
                  <v-radio-group v-model="currentSettings.currency" class="vertical-input" row>
                    <v-radio label="USD" value="USD" />
                    <v-radio label="Euro" value="EUR" />
                  </v-radio-group>
                </v-col>
              </v-row>
              <div class="captcha">
                <FormSwitch
                  v-model="currentSettings.captcha"
                  true-value="1"
                  false-value="0"
                  :label="$t('settings.captcha')"
                  class="pl-2 mt-0 pt-0 dark-label"
                />
              </div>
            </div>

            <div v-if="showSaleFieldContact || showSaleFieldExternalLink" class="forsale-customtext-switch mb-4">
              <Subtitle :text="$t('settings.sale_text')" />
              <v-select
                :items="availableSaleTexts"
                :label="$t('domain_index.for_sale_text')"
                v-model="currentSettings.custom_text"
                item-value="id"
                filled
                dense
                :menu-props="{ contentClass: 'sale-text-select', bottom: true, offsetY: true }"
                :hide-details="true"
                @change="onSaleTextChange"
              >
                <template v-slot:selection="data">
                  <Crop max-width="300px" :value="$f.implode(data.item.text, ' ')" />
                </template>
                <template v-slot:item="data">
                  <Crop max-width="300px" :value="$f.implode(data.item.text, ' ')" />
                </template>
              </v-select>

              <SaleTextsDialog @dataChanged="loadSaleTexts" />
            </div>
          </div>
        </v-col>
        <v-col cols="12" lg="6" order="2">
          <div v-if="showSaleFieldContact || showSaleFieldExternalLink" class="forsale-linkstyle-switch">
            <Subtitle :text="$t('settings.banner')">
              <HelpInfo :description="$t('settings.banner_description')" />
            </Subtitle>
            <v-img
              :src="currentSaleBannerPreviewImageFull"
              :data-filename="currentSaleBannerPreviewImageFile"
              class="forsale-linkstyle-previewimage"
              max-width="400"
            />
            <v-chip-group active-class="primary--text" :column="true" v-model="currentSettings.forsale_linkstyle">
              <v-chip
                class="mt-2 rounded"
                outlined
                v-for="(banner_style, index) in availableBannerStyles"
                :key="index"
                :value="banner_style.id"
                :disabled="isChipDisabled(banner_style)"
              >
                {{ banner_style.name }}
              </v-chip>
            </v-chip-group>
          </div>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col cols="12">
          <p>{{ $t('settings.custom_settings_disabled') }}</p>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import Price from '../others/Price'
import { rules } from '@/validation/rules'
import Crop from '../others/Crop'
import SaleTextsDialog from '@/components/settings/SaleTextsDialog.vue'
import SaleUrlsDialog from '@/components/settings/SaleUrlsDialog.vue'
import Subtitle from '@/components/app/Subtitle'
import HelpInfo from '../others/HelpInfo.vue'
import Logger from '@/util/Logger'
import FormSwitch from '@/components/app/FormSwitch'

export default {
  name: 'SettingsSale',
  props: {
    settings: Object,
    type: {
      String,
      default: 'domain'
    },
    showSaleSettings: {
      Boolean,
      default: true
    }
  },
  components: {
    HelpInfo,
    Subtitle,
    Price,
    Crop,
    SaleTextsDialog,
    SaleUrlsDialog,
    FormSwitch
  },
  data() {
    return {
      showSaleFields: {
        contact: false,
        externallink: false
      },
      currentTemplate: null,
      currentSaleBanner: null,
      currentSaleBannerPreviewImageFull: null,
      currentSaleBannerPreviewImageFile: null,
      currentSettings: this.$props.settings,
      availableSaleUrls: [],
      availableSaleTexts: [],
      rules: { ...rules },
      selectedSaleText: null
    }
  },
  watch: {
    'currentSettings.forsale_type': {
      handler(forsale_type) {
        this.showOrHideSaleFields(forsale_type)
      }
    },
    'currentSettings.forsale_template': {
      handler(forsale_template) {
        this.updateSaleTemplate(forsale_template)
      }
    },
    'currentSettings.forsale_linkstyle': {
      handler(forsale_linkstyle) {
        this.updateSaleBanner(forsale_linkstyle)
      }
    }
  },
  computed: {
    showSaleFieldContact() {
      return this.showSaleFields.contact
    },
    showSaleFieldExternalLink() {
      return this.showSaleFields.externallink
    },
    availableBannerStyles() {
      let saleBanners = this.$store.getters['sale_banner/getData']

      if (!saleBanners.data || !saleBanners.data.data) {
        return []
      }

      return saleBanners.data.data
    },
    availableContactSaleTemplates() {
      return this.$store.getters['sale_template/getByType']('contact')
    }
  },
  methods: {
    showOrHideSaleFields(type) {
      if (type === 'notforsale') {
        for (let field in this.showSaleFields) {
          this.showSaleFields[field] = false
        }
      } else if (type === 'contact') {
        this.showSaleFields.contact = true
        this.showSaleFields.externallink = false
      } else if (type === 'externallink') {
        this.showSaleFields.externallink = true
        this.showSaleFields.contact = false
      }
    },
    getSaleTemplateById(id) {
      return this.$store.getters['sale_template/getById'](id)
    },
    getSaleBannerById(id) {
      return this.$store.getters['sale_banner/getById'](id)
    },
    updateSaleBanner(forsale_linkstyle) {
      this.currentSaleBanner = this.getSaleBannerById(forsale_linkstyle)
      this.currentSaleBannerPreviewImageFile = 'sale_banner_' + forsale_linkstyle + '.png'
      this.currentSaleBannerPreviewImageFull = require('@/assets/images/sale_settings/' + this.currentSaleBannerPreviewImageFile)
    },
    updateSaleTemplate(forsale_template) {
      this.currentTemplate = this.getSaleTemplateById(forsale_template)
    },
    loadSaleUrls() {
      this.$store
        .dispatch('sale_url/fetchData')
        .then(response => (this.availableSaleUrls = response.data.data))
        .catch(error => Logger.log('Error loading sale urls', { error }))
    },
    async loadSaleTexts() {
      await this.$store
        .dispatch('sale_text/fetchData')
        .then(response => (this.availableSaleTexts = response.data.data))
        .catch(error => Logger.log('Error loading sale texts', { error }))

      if (this.currentSettings.custom_text !== null && this.currentSettings.custom_text >= 0) {
        this.findSaleTextById(this.currentSettings.custom_text)
      }
    },
    findSaleTextById(saleTextId) {
      this.selectedSaleText = this.availableSaleTexts.find(saleText => saleText.id === saleTextId)
    },
    onSaleTextChange(saleTextId) {
      this.findSaleTextById(saleTextId)
    },
    isChipDisabled(banner_style) {
      if (this.selectedSaleText === null || typeof this.selectedSaleText === 'undefined') {
        return true
      }

      if (typeof this.selectedSaleText.type === 'undefined') {
        return false
      }

      return banner_style.type !== this.selectedSaleText.type
    }
  },
  beforeMount() {
    this.$store
      .dispatch('sale_template/fetchData')
      .then(() => {
        if (this.currentSettings && this.currentSettings.forsale_template) {
          this.updateSaleTemplate(this.currentSettings.forsale_template)
        }
      })
      .catch(error => Logger.log('Error loading sale templates', { error }))
    this.$store
      .dispatch('sale_banner/fetchData')
      .then(() => {
        if (this.currentSettings && this.currentSettings.forsale_linkstyle) {
          this.updateSaleBanner(this.currentSettings.forsale_linkstyle)
        }
      })
      .catch(error => Logger.log('Error loading sale banners', { error }))

    this.loadSaleTexts()
    this.loadSaleUrls()

    if (this.currentSettings && this.currentSettings.forsale_type) {
      this.showOrHideSaleFields(this.currentSettings.forsale_type)
    }
  }
}
</script>

<style lang="sass">
@import '~@/sass/variables.sass'

.sale-settings
  .forsale-field
    .v-btn-toggle > .v-btn.v-btn
      opacity: unset
    .v-btn-toggle:not(.v-btn-toggle--group) .v-btn.v-btn
      border-color: $input_border
    .v-btn
      background: $background
      span
        color: $secondary-text
    .v-btn:hover
      color: $primary
      background: $primary-light
      span
        color: $primary
    .v-btn:before
      content: none
    .v-btn.v-btn--active
      background: $primary
      border: none
      span
        color: #FAFAFD
  .v-chip-group .v-chip--active
    border-color: $primary
  .vertical-input
    margin-top: 10px
    @media (max-width: 530px)
      margin-top: 0
      padding-top: 2px
</style>
