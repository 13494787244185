<template>
  <div>
    <ManageLink :label="$t('settings.manage_sale_urls_label')" @openDialog="showDialog = true" />
    <v-dialog v-model="showDialog" hide-overlay transition="dialog-bottom-transition" content-class="sale-urls-dialog">
      <v-container class="pa-4 dialog-background">
        <SaleUrlIndex @dataChanged="onDataChanged" />
        <v-btn icon class="close-button-sticky" @click="showDialog = false"><v-icon>mdi-close</v-icon></v-btn>
      </v-container>
    </v-dialog>
  </div>
</template>

<script>
import SaleUrlIndex from '@/pages/sale-url/Index.vue'
import ManageLink from '@/components/settings/ManageLink.vue'

export default {
  name: 'SaleUrlsDialog',
  components: { ManageLink, SaleUrlIndex },
  data() {
    return {
      showDialog: false
    }
  },
  methods: {
    onDataChanged() {
      this.showDialog = false
      this.$emit('dataChanged')
    }
  }
}
</script>

<style lang="sass">
@import '~@/sass/variables.sass'

#app
  .sale-urls-dialog
    height: 80%
    @media only screen and (min-width: 1264px)
      margin-left: 290px
  .dialog-background
    position: relative
  .close-button-sticky
    position: absolute
    top: 10px
    right: 10px
</style>
