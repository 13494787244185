<template>
  <div class="add-sale-text-form">
    <v-card class="pa-4">
      <Headline :text="$t('settings.add_sale_text')" />
      <v-form>
        <v-select
          @change="changeSaleTextFieldsForType"
          class="new-select-type"
          id="new-sale-type-select"
          :menu-props="{ contentClass: 'new-sale-type-select-menu', bottom: true, offsetY: true }"
          :items="['normal', 'diagonal']"
          :label="$t('settings.new_for_sale_text_type')"
          v-model="newSaleTextType"
          filled
          dense
        />
        <v-text-field
          v-if="newSaleTextType === 'normal'"
          :label="$t('settings.new_for_sale_text_normal_label')"
          v-model="newSaleTextNormal"
          :rules="[value => (value.length > 0 && value.length <= 100) || $t('settings.new_for_sale_text_normal_error')]"
          counter="100"
          id="sale-text-normal-1"
          ref="newSaleTextNormal"
          persistent-hint
          required
          filled
          dense
        />
        <v-text-field
          v-if="newSaleTextType === 'diagonal'"
          :label="$t('settings.new_for_sale_text_line1_label')"
          v-model="newSaleTextDiagonal1"
          :rules="[value => (value.length > 0 && value.length <= 15) || $t('settings.new_for_sale_text_line1_error')]"
          ref="newSaleTextDiagonal1"
          counter="15"
          id="sale-text-diagonal-1"
          persistent-hint
          required
          filled
          dense
        />
        <v-text-field
          v-if="newSaleTextType === 'diagonal'"
          :label="$t('settings.new_for_sale_text_line2_label')"
          v-model="newSaleTextDiagonal2"
          :rules="[value => value.length <= 24 || $t('settings.new_for_sale_text_line2_error')]"
          ref="newSaleTextDiagonal2"
          counter="24"
          id="sale-text-diagonal-2"
          persistent-hint
          required
          filled
          dense
        />
        <v-text-field
          v-if="newSaleTextType === 'diagonal'"
          :label="$t('settings.new_for_sale_text_line3_label')"
          v-model="newSaleTextDiagonal3"
          :rules="[value => value.length <= 28 || $t('settings.new_for_sale_text_line3_error')]"
          ref="newSaleTextDiagonal3"
          id="sale-text-diagonal-3"
          counter="28"
          persistent-hint
          required
          filled
          dense
        />

        <v-card-actions>
          <v-spacer></v-spacer>
          <PlainButton @click="$emit('sale_text:canceled')" class="dialog-close-btn">Cancel</PlainButton>
          <PrimaryButton @click="saveNewSaleText" class="save-new-sale-text">{{ $t('misc.add') }}</PrimaryButton>
        </v-card-actions>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import Headline from '@/components/app/Headline.vue'
import PlainButton from '@/components/app/PlainButton.vue'
import PrimaryButton from '@/components/app/PrimaryButton.vue'
import Logger from '@/util/Logger'

export default {
  name: 'SaleTextAdd',
  components: { Headline, PrimaryButton, PlainButton },
  data() {
    return {
      newSaleTextType: 'normal',
      newSaleTextNormal: '',
      newSaleTextDiagonal1: '',
      newSaleTextDiagonal2: '',
      newSaleTextDiagonal3: ''
    }
  },
  methods: {
    changeSaleTextFieldsForType(selectedOption) {
      if (selectedOption === 'normal') {
        this.$refs.newSaleTextDiagonal1.resetValidation()
        this.$refs.newSaleTextDiagonal2.resetValidation()
        this.$refs.newSaleTextDiagonal3.resetValidation()
      } else {
        this.$refs.newSaleTextNormal.resetValidation()
      }
    },
    saveNewSaleText() {
      let saleText = []
      if (this.newSaleTextType === 'normal' && this.saleTextNormalFieldsValid()) {
        saleText.push(this.newSaleTextNormal)
      } else if (this.newSaleTextType === 'diagonal' && this.saleTextDiagonalFieldsValid()) {
        saleText.push(this.newSaleTextDiagonal1)
        if (this.newSaleTextDiagonal2 !== '') {
          saleText.push(this.newSaleTextDiagonal2)
        }
        if (this.newSaleTextDiagonal3 !== '') {
          saleText.push(this.newSaleTextDiagonal3)
        }
      }
      if (saleText !== []) {
        let storeData = {
          text: saleText,
          type: this.newSaleTextType
        }

        this.$store
          .dispatch('sale_text/saveData', storeData)
          .then(async () => {
            this.$emit('sale_text:saved')

            await this.$store.dispatch('snackbar/showSuccessMessage', { message: this.$t('settings.new_for_sale_text_added') })
            this.resetSaleTextFields()
          })
          .catch(error => {
            Logger.log('Error saving new sale text', { error })
          })
      } else {
        this.$store.dispatch('snackbar/showErrorMessage', { message: this.$t('settings.new_for_sale_text_global_error') })
      }
    },
    saleTextNormalFieldsValid() {
      return this.$refs.newSaleTextNormal.validate() ?? true
    },
    saleTextDiagonalFieldsValid() {
      return (
        (this.$refs.newSaleTextDiagonal1.validate() && this.$refs.newSaleTextDiagonal2.validate() && this.$refs.newSaleTextDiagonal3.validate()) ??
        true
      )
    },
    resetSaleTextFields() {
      if (this.newSaleTextType === 'normal') {
        this.newSaleTextNormal = ''
        this.$refs.newSaleTextNormal.resetValidation()
      } else {
        this.newSaleTextDiagonal1 = ''
        this.newSaleTextDiagonal2 = ''
        this.newSaleTextDiagonal3 = ''
        this.$refs.newSaleTextDiagonal1.resetValidation()
        this.$refs.newSaleTextDiagonal2.resetValidation()
        this.$refs.newSaleTextDiagonal3.resetValidation()
      }
      this.newSaleTextType = 'normal'
    }
  }
}
</script>
