<template>
  <div id="domain-edit">
    <Headline :text="getHeading()" />
    <DomainEdit />
  </div>
</template>

<script>
import Headline from '@/components/app/Headline'
import DomainEdit from '@/components/domain/DomainEdit'

export default {
  components: {
    Headline,
    DomainEdit
  },
  methods: {
    getHeading() {
      return this.$t('domain_edit.heading') + ' "' + this.$route.params.domain + '"'
    }
  }
}
</script>
