<template>
  <v-switch
    :input-value="value"
    @change="onChange"
    :true-value="$props.trueValue"
    :false-value="$props.falseValue"
    :label="$props.label"
    hide-details
    inset
    @click="$emit('click')"
    :class="{
      'mt-0': true,
      'mb-8': true,
      'form-switch': true,
      'active-form-switch': isActive,
      'inactive-form-switch': !isActive,
      'disabled-form-switch': $props.isDisabled
    }"
    :disabled="$props.isDisabled"
  />
</template>

<script>
export default {
  name: 'FormSwitch',
  props: {
    label: {
      required: true
    },
    value: {
      required: true
    },
    trueValue: {
      required: true
    },
    falseValue: {
      required: true
    },
    isDisabled: {
      required: false,
      default: false
    }
  },
  data() {
    return {
      isActive: this.$props.trueValue === this.$props.value
    }
  },
  methods: {
    onChange(newValue) {
      this.isActive = newValue === this.$props.trueValue
      this.$emit('input', newValue)
    }
  }
}
</script>

<style lang="sass">
@import '~@/sass/variables.sass'

.form-switch.active-form-switch
  .v-input--switch__track
    background-color: $primary
    opacity: unset
  .v-input--switch__thumb
    background-color: #FFF

.form-switch.inactive-form-switch
  color: $secondary
  .v-input--switch__track
    background-color: $secondary
    opacity: unset
  .v-input--switch__thumb
    background-color: #FFF

.form-switch.disabled-form-switch
  .v-input--switch__track
    background-color: #DCDCDC
    opacity: unset
  .v-input--switch__thumb
    background-color: #65625F
</style>
